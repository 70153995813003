<template>
  <modal
    name="modal"
    width="95%"
    left="0"
    height="80%"
    class="modal"
    @before-close="beforeClose"
  >
    <h3 style="margin-top: 1rem">第一步：选择一个类别</h3>
    <select v-model="tousuType">
      <option>金额不对</option>
      <option>无领取资格</option>
      <option>重复发放</option>
      <option>数据有误</option>
      <option>对象已死亡</option>
      <option>其他情况</option>
    </select>
    <h3 style="margin-top: 1rem">第二步：输入手机号获取验证码</h3>
    <input
      type="text"
      placeholder="输入手机号"
      class="yzm-input"
      v-model="phone"
    />
    <br />
    <button
      @click="getYzm"
      class="yzm-button"
      v-bind:class="{ disabled: count_over }"
      :disabled="count_over"
    >
      {{ yzm_text }}
    </button>
    <div v-if="issend">
      <h3 style="margin-top: 1rem">第三步：输入刚获取到的验证码</h3>
      <input type="text" placeholder="输入验证码" v-model="yzm" />
      <button @click="checkYzm" class="button">提交</button>
      <button @click="hide" class="button gray" style="margin-top: 0.5rem">
        取消
      </button>
    </div>
  </modal>
</template>
<script>
import BASE from "../utils/base";

export default {
  name: "tousu_modal",
  components: {},
  props: ["moneyId", "remark", "addsan"],
  data: () => ({
    result: [],
    active: 1,
    todayView: null,
    totalView: null,
    resultList: [],
    phone: "",
    yzm: "",
    issend: false,
    tousuType: "金额不对",
    count_over: false,
    timer: null,
    yzm_text: "获取验证码",
  }),
  watch: {},
  methods: {
    hide() {
      this.$modal.hide("modal");
      console.log("this.$modal", this.$modal);
      console.log("hide");
      this.issend = false;
      this.count_over = false;
    },
    beforeClose() {
      this.issend = false;
      this.count_over = false;
      this.phone = "";
    },
    getYzm() {
      console.log(this.remark ? "hh" : "jj");
      const reg = /^1[3456789]\d{9}$/;
      if (reg.test(this.phone)) {
        this.axios
          .post(BASE.genUrl("phoneCode/send_msg", { phone: this.phone }))
          .then(() => {
            this.issend = true;
            this.count_over = 60;
            this.timer = setInterval(this.countdown, 1000);
            alert("验证码已发送！");
          });
      } else {
        this.$Toast("请输入正确的手机号码");
      }
    },
    countdown() {
      if (this.count_over > 1) {
        this.count_over -= 1;
        this.yzm_text = "重新获取验证码（" + this.count_over + "s）";
      } else {
        this.count_over = false;
        this.yzm_text = "获取验证码";
        clearInterval(this.timer);
      }
    },
    checkYzm() {
      let that = this;
      this.axios
        .post(
          BASE.genUrl("phoneCode/verify_msg", {
            phone: this.phone,
            code: this.yzm,
          })
        )
        .then((res) => {
          console.log(res);
          if (!res.data.success) {
            alert(res.data.message);
            return false;
          }
          that.submitData();
        });
    },
    submitData() {
      // let data = "DpHxKkMbPymRYCFi" + new Date().getTime();
      // let _data = CryptoJS.encrypt(data);

      let that = this;
      this.axios
        .post(
          BASE.genUrl("zxSuperviseLetter/" + (this.addsan ? "addsan" : "add")),
          {
            phone: this.phone,
            moneyId: this.moneyId,
            type: this.tousuType,
            remark: this.remark || "",
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            alert(res.data.message);
            that.phone = "";
            that.yzm = "";
            that.hide();
          } else {
            alert(res.data.message);
            that.phone = "";
            that.yzm = "";
            that.issend = false;
            this.count_over = false;
          }
        });
    },
  },
};
</script>
<style scoped>
.source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
  margin-bottom: 0.3rem;
}

.modal {
  z-index: 9999999;
}

.source a {
  color: #fff;
}

.modal h3 {
  margin-bottom: 0.5rem;
}

.button {
  display: block;
  background: #b51414;
  color: #fff;
  border: 0;
  padding: 0.5rem 2rem;
  margin: 1rem auto 0rem;
  border-radius: 0.5rem;
  width: 50%;
  font-size: 16px;
}

.modal {
  /* text-align: center; */
  font-size: 16px;
  border-radius: 0.5rem;
  /* padding: .5rem; */
}

.modal input {
  /* text-indent: .2rem; */
  padding: 0.5rem 1rem;
  font-size: 16px;
  width: 50%;
}

.yzm-input {
  /* border: 1px solid #999;
  border-radius: .5rem;
  border-right: 0; */
}

.yzm-button {
  padding: 0.5rem 1rem;
  background: #b51414;
  color: #fff;
  border: 0;
  margin-top: 1rem;
  border-radius: 0.5rem;
  font-size: 16px;
  /* width: 50%; */
  margin-bottom: 0.5rem;
}

.disabled {
  background: #ccc;
  color: #b51414;
}

.gray {
  background: #ccc;
  color: #fff;
}

.modal select {
  /* width: 50%; */
  font-size: 14px;
  text-align: center;
  padding: 0.5rem 1rem;
  /* margin-top: 1rem; */
}

.detail-footer {
  display: flex;
  flex-direction: row;
  font-size: 14px;
}

.detail-footer div {
  width: 50%;
}

.detail-footer div.r {
  text-align: right;
}

.detail-footer div.r a {
  border: 1px solid #b51414;
  color: #b51414;
  padding: 0.1rem 0.5rem;
}

.personInfo {
  background: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  text-align: left;
  border-radius: 0.5rem;
}

.footer {
  padding: 0.5rem 0;
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}

ol,
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 0.5rem;
}

.banner {
  width: 100%;
  height: auto;
}

.page {
  background: #b51414;
}

.detail {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
  background: #fff;
  text-align: left;
}

.personInfo span {
  color: #b51414;
}

.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}

.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

.container .news .list {
  text-align: left;
  padding: 0.8rem;
}

.info-title {
  margin-top: 2rem;
  padding: 0 0.5rem;
}

h3 {
  margin-bottom: 0.3rem;
}

.detail p {
  font-size: 14px;
  line-height: 25px;
}

.detail li {
  margin: 0 0.5rem;
  border-bottom: 1px solid #ccc;
}
</style>
